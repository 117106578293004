"use client";

import { useCityFilters } from "@hooks";
import { AnimatePresence } from "framer-motion";
import { memo } from "react";
import { ActiveFilterItem } from "./active-filter-item";

export const ActiveFilters = memo(() => {
  const { filters, onFindCityFilter, onRemove } = useCityFilters();

  if (!filters.length) return null;

  return (
    <div className="flex flex-wrap gap-2 lg:justify-end">
      <AnimatePresence>
        {filters.map((filter) => (
          <ActiveFilterItem
            key={filter.value}
            label={filter.key}
            filter={onFindCityFilter(filter)}
            onRemove={() => onRemove(filter)}
          />
        ))}
      </AnimatePresence>
    </div>
  );
});

ActiveFilters.displayName = "ActiveFilters";
