import { ActiveFilterItem } from "@components/explore";
import filterOptions from "@constants/filter-options";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { LandingSection } from "../landing-section";

const SHOWCASE_FILTERS = [
  {
    label: filterOptions[0].key,
    filter: filterOptions[0].values[1],
  },
  {
    label: filterOptions[1].key,
    filter: filterOptions[1].values[2],
  },
  {
    label: filterOptions[4].key,
    filter: filterOptions[4].values[0],
  },
  {
    label: filterOptions[2].key,
    filter: filterOptions[2].values[0],
  },
  {
    label: filterOptions[5].key,
    filter: filterOptions[5].values[1],
  },
  {
    label: filterOptions[4].key,
    filter: filterOptions[4].values[4],
  },
  {
    label: filterOptions[3].key,
    filter: filterOptions[3].values[3],
  },
  {
    label: filterOptions[0].key,
    filter: filterOptions[0].values[3],
  },
  {
    label: filterOptions[1].key,
    filter: filterOptions[1].values[0],
  },
  {
    label: filterOptions[4].key,
    filter: filterOptions[4].values[1],
  },
  {
    label: filterOptions[1].key,
    filter: filterOptions[1].values[1],
  },
  {
    label: filterOptions[5].key,
    filter: filterOptions[5].values[0],
  },
];

export const LandingSmartFiltersSection = () => {
  const { t } = useTranslation();

  return (
    <LandingSection id="filters" className="min-h-[45rem]">
      <div className="flex flex-col-reverse gap-20 px-1 md:px-4 lg:px-10 lg:flex-row">
        <div className="w-full lg:w-1/2">
          <div className="flex flex-wrap items-center justify-center gap-2 mb-6">
            {SHOWCASE_FILTERS.map((item, index) => (
              <ActiveFilterItem
                key={index}
                label={item.label}
                filter={item.filter}
                isRemoveEnabled={false}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-center w-full lg:w-1/2">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.4, delay: 0.2 }}
            variants={{
              visible: { opacity: 1, x: 0, animationDelay: "500" },
              hidden: { opacity: 0, x: 200 },
            }}
          >
            <h4 className="my-4 text-4xl font-bold tracking-tight md:text-5xl">
              {t("pages.landing.filtersTitle")}
            </h4>
            <p className="max-w-2xl mx-auto text-xl">
              {t("pages.landing.filtersDescription")}
            </p>
          </motion.div>
        </div>
      </div>
    </LandingSection>
  );
};
