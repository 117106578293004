import {
  Command,
  CommandItem,
  CommandList,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui";
import { useDebounce } from "@hooks";
import { IconBackspace, IconLoader2, IconSearch } from "@tabler/icons-react";
import { ISelectOption } from "@types";
import { parseCityOptionsAsync, parseHighlightSearchTerm } from "@utils/parse";
import { getCityLink } from "@utils/routes";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

const MAX_ITEMS_DISPLAYED = 4;

export const LandingSearchCta = () => {
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearch = useDebounce(searchString, 500);
  const [searchResults, setSearchResults] = useState<ISelectOption[]>([]);

  // Search on type
  useEffect(() => {
    if (!debouncedSearch) return setSearchResults([]);

    (async () => {
      try {
        setIsLoading(true);
        const result = await parseCityOptionsAsync(debouncedSearch);
        setSearchResults(result.slice(0, MAX_ITEMS_DISPLAYED));
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [debouncedSearch]);

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  }, []);

  const onInputClear = useCallback(() => {
    setSearchString("");
    setSearchResults([]);
  }, []);

  const onLoseBlur = useCallback(() => {
    searchInputRef.current?.blur();
    setSearchResults([]);
  }, []);

  return (
    <div className="flex justify-center w-[23rem] md:w-[30rem] mx-auto mt-8">
      <Popover open={Boolean(searchString)}>
        <PopoverTrigger asChild>
          <div className="relative flex w-full max-w-[30rem]">
            <Input
              className="h-16 px-6 text-[1.25rem] border-2 border-input bg-background rounded-xl hover:bg-background/60 focus-visible:bg-background/60"
              ref={searchInputRef}
              placeholder={t("pages.landing.heroPlaceholder")}
              value={searchString}
              onChange={onInputChange}
              onBlur={(e) => {
                e.relatedTarget
                  ? searchInputRef.current?.focus()
                  : onLoseBlur();
              }}
            />
            <div className="absolute flex items-center h-full opacity-40 right-5">
              {isLoading ? (
                <IconLoader2 className="animate-spin" size={26} />
              ) : searchString ? (
                <IconBackspace
                  className="cursor-pointer"
                  onClick={onInputClear}
                  size={26}
                />
              ) : (
                <IconSearch size={26} />
              )}
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-[23rem] md:w-[30rem] p-2 bg-background"
          hidden={!searchResults.length}
        >
          <Command className="bg-transparent">
            <CommandList>
              {searchResults?.map((item, index) => (
                <Link key={index} passHref href={getCityLink(item.value)}>
                  <CommandItem
                    className="px-4 cursor-pointer aria-selected:bg-primary/50"
                    value={item.value}
                  >
                    <div className="flex items-center gap-2 px-2">
                      {item.icon}
                      <p
                        className="text-xl font-medium tracking-tight truncate"
                        dangerouslySetInnerHTML={{
                          __html: parseHighlightSearchTerm(
                            item.label,
                            debouncedSearch
                          ),
                        }}
                      />
                    </div>
                  </CommandItem>
                </Link>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
