import { cn } from "@/lib/utils";
import { HTMLProps } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

export const LandingSection = ({ children, className, ...props }: Props) => (
  <section
    className={cn(
      "flex bg-background/10 items-center justify-center min-h-[calc(100vh-5rem)]",
      className
    )}
    {...props}
  >
    <div className="container items-center text-center">{children}</div>
  </section>
);
