"use client";

import { Button } from "@components/ui";
import { useAppStore } from "@store";
import {
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";

const SIDEBAR_ICON = {
  expand: <IconLayoutSidebarLeftExpand size={20} />,
  collapse: <IconLayoutSidebarLeftCollapse size={20} />,
};

export const FilterToggler = () => {
  const { t } = useTranslation();
  const { isFiltersOn, isFiltersMobileOn, toggleFilters, setFiltersMobile } =
    useAppStore();

  return (
    <>
      <Button
        className="md:hidden"
        variant="secondary"
        onClick={() => setFiltersMobile(true)}
      >
        {SIDEBAR_ICON[isFiltersMobileOn ? "collapse" : "expand"]}
        {t(`pages.city.${isFiltersMobileOn ? "hide" : "show"}Filters`)}
      </Button>
      <Button
        className="hidden md:flex"
        variant="secondary"
        onClick={toggleFilters}
      >
        {SIDEBAR_ICON[isFiltersOn ? "collapse" : "expand"]}
        {t(`pages.city.${isFiltersOn ? "hide" : "show"}Filters`)}
      </Button>
    </>
  );
};
