import { cn } from "@/lib/utils";
import { Button, ButtonProps, Tooltip } from "@components/ui";
import { ICityFilter } from "@types";
import { useTranslation } from "next-i18next";
import Image from "next/image";

interface Props extends ButtonProps {
  cityFilter: ICityFilter;
  isSelected?: boolean;
}

export const FilterPillButton = ({
  cityFilter: { label, image, tooltip },
  isSelected,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip tooltip={tooltip && t(tooltip)} asChild>
      <Button
        variant={isSelected ? "default" : "secondary"}
        className={cn("border border-border h-8 px-3 w-fit", isSelected)}
        {...rest}
      >
        <div className="flex gap-2">
          <Image alt="filter image" src={image} height={22} width={22} />
          <p className="font-medium tracking-tight">{t(label)}</p>
        </div>
      </Button>
    </Tooltip>
  );
};
