"use client";

import { CityPlacard } from "@components/city";
import { PageError } from "@components/layout";
import { Skeleton } from "@components/ui";
import apiRoutes from "@constants/api-routes";
import appConfig from "@constants/app-config";
import { useCityFilters, useGeolocation, useInfiniteScroll } from "@hooks";
import { ICity } from "@types";
import { fetcher, getApiUrl } from "@utils/api";
import { parseFilters, parseSortBy } from "@utils/parse";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo, useRef } from "react";
import useSWRInfinite from "swr/infinite";

interface Response {
  cities: any[];
  hasMore: boolean;
}

const useResults = () => {
  const { filters, sort, sortOrder } = useCityFilters();
  const geolocation = useGeolocation();

  const getKey = useCallback(
    (index: number, previous: Response) => {
      if (previous && !previous.hasMore) return null;
      return getApiUrl(apiRoutes.explore, null, {
        limit: appConfig.cities.itemsToShow,
        page: index + 1,
        latitude: geolocation?.lat,
        longitude: geolocation?.lon,
        ...parseSortBy(sort, sortOrder),
        ...parseFilters(filters),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, sort, sortOrder]
  );

  return useSWRInfinite<Response>(getKey, fetcher, {
    initialSize: 1,
    revalidateFirstPage: false,
  });
};

export const ExploreResults = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { filters } = useCityFilters();
  const { data, error, setSize, isLoading, isValidating } = useResults();
  useInfiniteScroll(containerRef, () =>
    isValidating ? {} : setSize((prev) => prev + 1)
  );
  const cities: ICity[] = useMemo(() => {
    return data ? data.flatMap((page) => page.cities) : [];
  }, [data]);
  const hasMore = useMemo(() => {
    return data ? data[data.length - 1]?.hasMore : false;
  }, [data]);
  const isNoResults = useMemo(
    () => Boolean(filters.length && !isValidating && !cities.length),
    [cities.length, filters.length, isValidating]
  );

  if (error) return <PageError />;

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {Boolean(cities?.length) && (
        <div className="mb-2 grid-dynamic" ref={containerRef}>
          {cities.map((item, index) => (
            <CityPlacard key={index} data={item} />
          ))}
        </div>
      )}

      {isNoResults && (
        <div className="flex flex-col w-full max-w-lg mx-auto my-20 text-center">
          <p className="text-3xl font-bold tracking-tight">
            {t("pages.city.noFilters")}
          </p>
          <p className="text-lg">{t("pages.city.noFiltersDescription")}</p>
        </div>
      )}

      {((isValidating && hasMore) || isLoading) && <SkeletonLoader />}
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="grid-dynamic">
    {Array.from({ length: appConfig.cities.itemsToShow }).map((_, index) => (
      <Skeleton className="w-full h-52" key={index} />
    ))}
  </div>
);
