import { Button } from "@components/ui";
import { useCityFilters } from "@hooks";
import { useTranslation } from "next-i18next";
import { FilterToggler } from "./filter-toggler";
import { SortByFilter } from "./sort-by-filter";

export const FiltersSummary = () => {
  const { t } = useTranslation();
  const { filters, sort, onReset } = useCityFilters();

  return (
    <div className="flex flex-wrap gap-2 lg:flex-nowrap">
      <FilterToggler />
      <SortByFilter />
      {Boolean(filters.length || sort) && (
        <Button variant="ghost" onClick={onReset}>
          {t("pages.city.resetFilters")}
        </Button>
      )}
    </div>
  );
};
