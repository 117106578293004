import { ProLabel } from "@components/pro";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@components/ui";
import { IconArrowLeft, IconSparkles } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { LandingSection } from "../landing-section";

export const LandingAdvisorSection = () => {
  const { t } = useTranslation();

  return (
    <LandingSection id="advisor" className="min-h-[45rem]">
      <div className="flex flex-col gap-20 px-1 md:px-4 lg:px-10 lg:flex-row">
        <div className="flex flex-col justify-center w-full lg:w-1/2">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 0.4 }}
            variants={{
              visible: { opacity: 1, scale: 1, x: 0, animationDelay: "500" },
              hidden: { scale: 0.88, opacity: 0, x: -200 },
            }}
          >
            <h4 className="my-4 text-4xl font-bold tracking-tight md:text-5xl">
              {t("pages.landing.advisorTitle")}
            </h4>
            <p className="max-w-2xl mx-auto text-xl">
              {t("pages.landing.advisorDescription")}
            </p>
          </motion.div>
        </div>
        <div className="w-full lg:w-1/2">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 0.4 }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0, animationDelay: "200" },
              hidden: { scale: 0.6, opacity: 0, y: 200 },
            }}
          >
            <Card
              className="w-full max-h-[32rem] lg:max-h-none overflow-scroll bg-bottom bg-no-repeat bg-cover shadow-xl rotate-3 rounded-xl text-neutral-100 bg-clip-padding bg-black/70"
              bgImage="/backgrounds/midnight-river.webp"
            >
              <CardHeader className="max-w-3xl mx-auto">
                <div className="flex justify-between w-full gap-2">
                  <div className="flex items-center gap-2">
                    <IconSparkles size={32} />
                    <div className="flex flex-col items-start">
                      <CardTitle className="text-xl">
                        {t("pages.city.advisor.title")}
                      </CardTitle>
                      <CardDescription className="text-neutral-100/70">
                        {t("pages.city.advisor.subtitle")}
                      </CardDescription>
                    </div>
                  </div>
                  <ProLabel tokens={2} />
                </div>
              </CardHeader>
              <CardContent className="flex max-w-3xl mx-auto">
                <div className="flex flex-col items-start overflow-hidden">
                  <div className="flex justify-between w-full">
                    <div className="flex flex-col">
                      <div className="flex items-baseline gap-1">
                        <p className="text-4xl font-bold tracking-tight">7</p>
                        <p className="font-semibold text-neutral-100/70">/10</p>
                      </div>
                      <p className="text-sm font-bold uppercase text-neutral-100/70">
                        {t("pages.city.advisor.ourRecommendation")}
                      </p>
                    </div>
                    <Button
                      variant="outline"
                      className="text-foreground hover:bg-background/80"
                      size="sm"
                      disabled
                    >
                      <IconArrowLeft size={18} />
                      {t("actions.back")}
                    </Button>
                  </div>
                  <div className="flex flex-col gap-3 text-start">
                    <p>
                      {`Warsaw, the capital of Poland, scores a 7 out of 10 for
                      your long-term relocation needs. The city is known for its
                      rich history, vibrant culture, and excellent public
                      transportation system. It offers a high standard of living
                      with affordable cost of living compared to other European
                      capitals. The city is also home to many international
                      companies, providing ample job opportunities.
                      Additionally, Warsaw is a hub for education and research,
                      with numerous universities and research institutions. The
                      city's healthcare system is also commendable. However, the
                      language barrier could be a challenge as Polish is the
                      primary language spoken, although English is increasingly
                      common especially among the younger population.`}
                    </p>
                    <Alert className="flex flex-col items-start gap-2 my-1">
                      <p className="mt-1 text-sm font-bold leading-none uppercase">
                        {t("pages.city.advisor.concernsTitle")}
                      </p>
                      <p>{`You should be aware of the language barrier, as Polish is
                      the primary language spoken. The winters in Warsaw can be
                      quite harsh with heavy snowfall. Also, while the city is
                      generally safe, like any other major city, it has areas
                      that are best to avoid at night.`}</p>
                    </Alert>
                  </div>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </div>
      </div>
    </LandingSection>
  );
};
