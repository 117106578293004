"use client";

import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@components/ui";
import { valuesFiltersSortBy } from "@constants/form-values";
import { useCityFilters } from "@hooks";
import {
  IconArrowsSort,
  IconChevronDown,
  IconSortAscending2,
  IconSortDescending2,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";

export const SortByFilter = () => {
  const { t } = useTranslation();
  const { sort, setSort, sortOrder, setSortOrder, onFindSortByFilter } =
    useCityFilters();
  const sortingIcon = useMemo(() => {
    if (!sort) return <IconArrowsSort size={18} />;
    if (sortOrder === "asc") return <IconSortAscending2 size={18} />;
    return <IconSortDescending2 />;
  }, [sort, sortOrder]);

  const onToggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const onToggleSortBy = (key: string) => {
    if (sort === key) onToggleSortOrder();
    setSort(key);
  };

  const getCurrentItem = useCallback(() => {
    if (!sort) return null;
    const { label } = onFindSortByFilter(sort);
    return `: ${t(label)}`;
  }, [t, sort, onFindSortByFilter]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          aria-label="Switch language"
          variant="secondary"
          className="flex items-center gap-2 text-base"
        >
          {sortingIcon}
          {t("filters.sortBy")}
          {getCurrentItem()}
          <IconChevronDown size={20} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[10rem]" side="bottom" align="start">
        {valuesFiltersSortBy.map((item) => (
          <DropdownMenuCheckboxItem
            key={item.value}
            onClick={() => onToggleSortBy(item.value)}
            checked={sort === item.value}
          >
            {t(item.label)}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
