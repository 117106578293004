"use client";

import { Card, CardContent, Drawer, DrawerContent } from "@components/ui";
import filterOptions from "@constants/filter-options";
import { useCityFilters } from "@hooks/use-city-filters";
import { useAppStore } from "@store";
import { useTranslation } from "next-i18next";
import { FilterPillButton } from "./";

export const SmartFiltersSidebar = () => {
  const { isFiltersOn, isFiltersMobileOn, setFiltersMobile } = useAppStore();

  if (!isFiltersOn) return null;

  return (
    <>
      <aside className="hidden max-w-[22rem] lg:block">
        <Card className="w-full py-4 overflow-hidden">
          <CardContent>
            <PartialContent />
          </CardContent>
        </Card>
      </aside>
      {isFiltersMobileOn && (
        <Drawer open={isFiltersMobileOn} onOpenChange={setFiltersMobile}>
          <DrawerContent>
            <div className="overflow-y-scroll ps-1">
              <PartialContent />
            </div>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

const PartialContent = () => {
  const { t } = useTranslation();
  const { getIsSelected, onToggle } = useCityFilters();

  return (
    <>
      {filterOptions.map(({ key, values }) => (
        <div key={key} className="flex flex-col gap-2 mb-4">
          <p className="text-sm font-bold tracking-tight uppercase text-muted-foreground">
            {t(`filters.${key}`)}
          </p>

          <div className="flex flex-wrap gap-2">
            {values.map((item, index) => (
              <FilterPillButton
                key={index}
                cityFilter={item}
                isSelected={getIsSelected({ key, value: item.value })}
                onClick={() =>
                  onToggle({ key, value: item.value }, item.isExclusive)
                }
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
