import {
  LandingAdvisorSection,
  LandingCtaSection,
  LandingSmartFiltersSection,
  LandingStatisticsSection,
} from "@components/landing/sections";
import { LandingHeader } from "@components/layout";
import { Footer } from "@components/layout/footer";
import { fetchLandingData } from "@services";
import { ILandingResponse } from "@types";
import { GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export default function Page({ data }: { data: ILandingResponse }) {
  return (
    <div className="flex flex-col w-full h-full overflow-x-hidden scroll-smooth">
      <LandingHeader />
      <LandingCtaSection />
      <LandingStatisticsSection data={data} />
      <LandingSmartFiltersSection />
      <LandingAdvisorSection />
      <Footer />
    </div>
  );
}

export const getStaticProps = async (context: GetStaticPropsContext) => {
  try {
    // Fetch landing data
    const data = await fetchLandingData();

    // Fetch translations
    const locale = context.locale ?? "en";
    const translations = await serverSideTranslations(locale, ["common"]);

    return { props: { ...translations, data } };
  } catch (error) {
    console.error(error);
    return {
      props: {
        ...(await serverSideTranslations(context.locale || "en", ["common"])),
      },
      notFound: true,
    };
  }
};
