import { useTranslation } from "next-i18next";
import { LandingSection } from "../landing-section";
import { LandingSearchCta } from "../search-cta";

export const LandingCtaSection = () => {
  const { t } = useTranslation();

  return (
    <LandingSection>
      <div className="px-1 md:px-4 lg:px-10">
        <h1
          className="max-w-screen-md mx-auto mb-4 text-4xl font-bold tracking-tight md:text-5xl xl:text-6xl"
          dangerouslySetInnerHTML={{
            __html: t("pages.landing.heroTitle"),
          }}
        ></h1>

        <p
          className="max-w-2xl mx-auto text-lg tracking-tight text-foreground/80 lg:text-xl"
          dangerouslySetInnerHTML={{
            __html: t("pages.landing.heroDescription"),
          }}
        />

        <LandingSearchCta />
      </div>
    </LandingSection>
  );
};
