import { CityScoresSection, CityWeatherTilesSection } from "@components/city";
import { CountryIconName } from "@components/country";
import { Card } from "@components/ui";
import { ILandingResponse } from "@types";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { LandingSection } from "../landing-section";

interface Props {
  data?: ILandingResponse;
}

export const LandingStatisticsSection = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <LandingSection id="statistics" className="min-h-[45rem]">
      <div className="flex flex-col gap-20 px-1 md:px-4 lg:px-10 lg:flex-row">
        <div className="flex flex-col justify-center w-full lg:w-1/2">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 0.4 }}
            variants={{
              visible: { opacity: 1, scale: 1, x: 0, animationDelay: "500" },
              hidden: { scale: 0.88, opacity: 0, x: -200 },
            }}
          >
            <h4 className="my-4 text-4xl font-bold tracking-tight md:text-5xl">
              {t("pages.landing.statisticsTitle")}
            </h4>
            <p className="max-w-2xl mx-auto text-xl">
              {t("pages.landing.statisticsDescription")}
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col items-center w-full lg:w-1/2">
          {/* First City data */}
          {data?.city1 && (
            <div className="justify-center px-6 py-4 translate-y-7 -rotate-2">
              <Card className="relative z-10 items-center px-4 py-2 translate-x-4 translate-y-2 rotate-3 bg-card w-fit">
                <CountryIconName
                  isLinkDisabled
                  code={data.city1.countryCode}
                  name={data.city1.name}
                />
              </Card>

              <CityWeatherTilesSection className="w-fit" data={data.city1} />
            </div>
          )}

          {/* Second City data */}
          {data?.city2 && (
            <div className="w-full max-w-3xl px-6 py-4 -translate-y-12 rotate-2">
              <Card className="relative z-10 px-4 py-2 -translate-x-8 translate-y-5 -rotate-3 bg-card w-fit">
                <CountryIconName
                  isLinkDisabled
                  code={data.city2.countryCode}
                  name={data.city2.name}
                />
              </Card>

              <CityScoresSection data={data.city2} className="h-fit" />
            </div>
          )}
        </div>
      </div>
    </LandingSection>
  );
};
