import { Button, Tooltip } from "@components/ui";
import { IconX } from "@tabler/icons-react";
import { ICityFilter } from "@types";
import { useTranslation } from "next-i18next";
import Image from "next/image";

interface Props {
  label: string;
  filter: ICityFilter;
  onRemove?: () => void;
  isRemoveEnabled?: boolean;
}

export const ActiveFilterItem = ({
  label,
  filter,
  onRemove,
  isRemoveEnabled = true,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip tooltip={t(filter.label)} asChild>
      <div className="flex items-center justify-center h-12 px-3 overflow-hidden border rounded-md shadow border-border max-w-52 w-fit bg-card">
        <div className="flex items-center gap-3">
          <Image alt="filter image" src={filter.image} height={28} width={28} />

          <div className="flex flex-col truncate">
            <p className="w-full text-xs font-semibold uppercase truncate text-start text-muted-foreground">
              {t(`filters.${label}`)}
            </p>
            <p className="w-full text-sm leading-tight truncate text-start max-w-32">
              {t(filter.label)}
            </p>
          </div>

          <div>
            {isRemoveEnabled && (
              <Tooltip tooltip={t("pages.city.removeFilter")} asChild>
                <Button
                  aria-label="remove filter"
                  size="icon"
                  variant="ghost"
                  className="w-8 h-8 min-w-fit"
                  onClick={onRemove}
                >
                  <IconX size={22} />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
